// Color system
$primary-color: #0a84b7;
$bg-primary-color: #0a84b7;

$header-cartes-dashboard-bg: #026085 ;
$header-cartes-dashboard-color: white;

$bleu-idhome: #026085 ; /* aussi pour le bandeau du header */

$gris-side-menu: #343a40;

$input-group-addon-bg: #299bc4;

$gris-textes-dashboard: #343a40;

$bleu-liseret-client:#026085 ;

/* ci-dessous de la personnalisation de certaines classes CSS */

.idhome-login {
  background: rgb(32, 90, 134);
  background: linear-gradient(
    90deg,
    rgba(32, 90, 134, 1) 0%,
    rgba(50, 133, 191, 1) 42%,
    rgba(27, 73, 129, 1) 100%
  );
}

$titre-login-couleur: #fff;
$sous-titre-login-couleur: #fff;

/* Ci dessous des éléments de personnalisation du thème ant */
.card {
  flex: 1;
  margin: 1rem;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

thead[class*="ant-table-thead"] th {
  background-color: white !important;
  color: black !important;
  font-weight: 400;
  border-bottom-color: $primary-color;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0px !important;
}

.ant-list-item-action {
  flex: 1 1 auto;
  margin-left: 0;
  padding: 0;
  list-style: none;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  color: #fff;
  background: $primary-color;
  border-color: $primary-color;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border: 0px solid #d9d9d9;
}
.ant-input-group > .ant-input:last-child:focus,
.ant-input-group-addon:last-child:focus,
.ant-input-group > .ant-input:last-child:hover,
.ant-input-group-addon:last-child:hover {
  color: #fff;
  background: $input-group-addon-bg;
  border-color: $input-group-addon-bg;
}

tr.ant-table-expanded-row > td {
  background-color: #eff2f5 !important;
  padding: 5px 40px;
}

.ant-card-body {
  padding: 0px;
  margin: 24px;
}

th.ant-descriptions-item-label {
  background-color: #f4f4f4!important;
}

.pack-radio-group > .ant-radio-button-wrapper-checked {
  background:#65ae43 !important;
  border-color:#65ae43 !important;
  box-shadow:0 0 0 0 !important;
}
