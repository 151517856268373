@import "variables";

@media (min-width: map-get($grid-breakpoints, sm)) {
    .show-mobile-hide-desktop {
        display: none;
    }
}
@media (max-width: map-get($grid-breakpoints, sm)) {
    .show-desktop-hide-mobile {
        display: none;
    }
}
