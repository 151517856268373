.header_wrap {
    transition: all 0.5s ease 0s;
    background-color: #fff;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
}
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 992px){
    .container {
        max-width: 960px;
    }
}
@media (min-width: 768px){
    .container {
        max-width: 720px;
    }
}
@media (min-width: 576px){
    .container {
        max-width: 540px;
    }
}

@media (min-width: 992px){
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
}
.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
a {
    text-decoration: none;
    -webkit-transition: all 0.5s ease;
    transition: all .5s ease;
}

.light_skin .logo_light {
    display: block;
}
.navbar-brand img {
    max-width: 182px;
}
.logo_light, .logo_dark {
    display: none;
}
img {
    max-width: 100%;
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-expand-lg .navbar-nav > li {
    margin-left: 1px;
}
.navbar-expand-lg .navbar-nav li {
    position: relative;
    list-style: none;
}
.dropdown, .dropleft, .dropright, .dropup {
    position: relative;
}

.dropdown:hover{
    color: #027ba8;
}

ul, li, ol {
    margin: 0;
    padding: 0;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.nav-link {
    color: #000000;
    display: block;
    padding: .5rem 1rem;
}
.dropdown-toggle {
    white-space: nowrap;
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
}
.navbar-nav .dropdown-menu {
    background-color: #333333;
    border: 0 none;
    border-radius: 0;
    margin: 0;
    min-width: 12rem;
    padding: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}

navbar-expand-lg .navbar-nav .dropdown-item {
    padding: 8px 25px 8px 10px;
    color: #bdbdbd;
    font-size: 14px;
    text-transform: capitalize;
}
@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-toggle::after, .dropdown-toggler::after {
    border: 0 none;
    content: "\f3d0";
    font-family: ionicons;
    height: auto;
    line-height: normal;
    margin-left: 5px;
    vertical-align: middle;
    width: auto;
}

@media only screen and (max-width: 991px){
    .dropdown-toggle::after {
        float: right;
    }
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.navbar-expand-lg .navbar-nav > li {
    margin-left: 1px;
}
.navbar-expand-lg .navbar-nav li {
    position: relative;
    list-style: none;
}
.dropdown, .dropleft, .dropright, .dropup {
    position: relative;
}

.navbar-expand-lg .navbar-nav > li > .nav-link {
    font-size: 13px;
    font-weight: 400;
    padding: 25px 10px;
    text-transform: uppercase;
}

@media only screen and (max-width: 991px){
    .navbar-expand-lg .navbar-nav > li > .nav-link, .nav-fixed .navbar-expand-lg .navbar-nav > li > .nav-link, .bottom-header .navbar-expand-lg .navbar-nav > li > .nav-link {
        padding: 10px 10px;
    }
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu li a.active, .navbar-expand-lg .navbar-nav .dropdown-menu li a:hover, .navbar-expand-lg .navbar-nav .dropdown-menu > ul > li:hover > a, .navbar-expand-lg .navbar-nav .dropdown-menu > ul > .mega-menu-col ul > li:hover > a {
        background-color: rgba(0,0,0,0.2);
        color: #fff;
    }
}

@media (max-width: 992px){
    .active {
        display: block !important;
        margin-bottom: 5px !important;
    }
    .dropdown-toggle{
        pointer-events: none;
        cursor: pointer;
        text-decoration: none;
    }
    .dropdown{
        cursor: pointer;
    }
}

.dropdown-menu{
    display: none;
    position: absolute;
}

.navbar-expand-lg .navbar-nav .dropdown-item {
    padding: 8px 25px 8px 10px;
    color: #bdbdbd;
    font-size: 14px;
    text-transform: capitalize;
}

.navbar .menu-icon {
    cursor: pointer;
    display: inline-block;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.navbar .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    width: 18px;
}

.navbar .menu-icon .navicon:before,
.navbar .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.navbar .menu-icon .navicon:before {
    top: 5px;
}

.navbar .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.navbar .menu-btn {
    display: none;
}

.navbar .menu-btn:checked ~ .menu {
    max-height: 240px;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.navbar .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.navbar .menu-btn:checked ~ .collapse:not(.show) {
    display: block;
}

.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.navbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 999px) {
    .navbar-nav .dropdown {
        float: left;
    }
    .navbar-nav li a {
        padding: 20px 30px;
    }
    .navbar .navbar-nav {
        clear: none;
        float: right;
        max-height: none;
    }
    .navbar .menu-icon {
        display: none;
    }
}
