@import "../../../styles/variables";

.border-bottom-liseret {
  border-bottom:solid;
  border-color: $bleu-idhome;
  color: #343a40;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.titre-pack-website {
  color: $bleu-idhome;
  font-size: 1.5rem;
  font-weight: 600;
  
}

.soustitre-pack-website {
  color: $bleu-idhome;
  font-size: 1rem;
  font-weight: 400;
  
}

.ant-input::placeholder {
  // color: #bfbfbf;
  color: #848484!important;
}

.selecteur-template > .ant-select-selector{
  height: 88px!important;
}

.template-option-container {
  padding-top: 10px;
}

.template-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0.3rem;
  }

  a {
    font-size: 12px;
  }
}