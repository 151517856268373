@import "../../styles/variables";

.titre-pack {
  color: #0a84b7;
  font-size: 1.3rem;
  font-weight: 400;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    color: white;
  }
}

.soustitre-pack {
  color: rgb(10, 132, 183);
  font-size: 0.8rem;
  font-weight: 400;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    color: white;
    font-size: 1rem;
  }
}
