@import "../../styles/variables";

.titre-login {
  color: $titre-login-couleur;
  font-size: 2rem;
  font-weight: 800;
  margin-left: 1rem;
  margin-bottom: 0.7rem;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    font-size: 2rem;
    margin-left: 1.8rem;
  }
}

.soustitre-login {
  color: $sous-titre-login-couleur;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 1rem;
  line-height: 1.5rem;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    font-size: 1.4rem;
    margin-left: 1.8rem;
  }
}

.text-login {
  max-width: auto;
  height: auto;
  padding-left: auto;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    padding-left: 10rem;
    max-width: 600px;
  }
}

.form-card__img--left {
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
}

.cta-login {
  color: #4c8ee0;
}

.logo-idhome-login {
  max-width: 200px;
  height: auto;
}

.idhome-login-container {
  max-width: 1280px;
  margin: auto;
}

.idhome-login-background {
  min-height: 93vh;
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
  }
}
