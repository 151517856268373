.cover {
  position: relative;
  background: $white;
  padding: 6.5em 0;

  h1 {
    font-weight: $font-weight-200;
    font-size: $h1-font-size;
    margin-bottom: 1.625rem;
  }

  .lead {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: $font-weight-light;
    opacity: 0.9;
    margin-bottom: 1.625rem;
  }

  .btn-cta {
    margin-top: 0.875rem;
  }
}

.cover-bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.cover-color-reverse {
  color: $body-color-reverse;
}
