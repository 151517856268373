// Themes
// --------------------------------------------------
$theme_dark_text_color: rgba(255, 255, 255, 0.7);
$theme_dark_text_color_light: rgba(255, 255, 255, 0.25);

$theme_dark_header_bg_color: lighten($dark, 6%);
$theme_dark_page_bg: lighten($dark, 2.5%);
$theme_dark_box_bg: $theme_dark_header_bg_color;

$theme_gray_header_bg_color: grayscale(
  $theme_dark_header_bg_color
); // or #4c4c4c
$theme_gray_page_bg: grayscale($theme_dark_page_bg); // #444;
$theme_gray_text_color: $theme_dark_text_color;
$theme_gray_box_bg: $theme_gray_header_bg_color;

.bg-idhome-header {
  background-color: $bleu-idhome;
}

.bg-idhome-side-menu {
  // background-color: $gris-side-menu;
  background-color:white;
}

.cover-bg-img-idhome {
  // background-size: contain;
  background-repeat: no-repeat;
}

.cover-liseret {
  border-bottom-style: solid;
  border-bottom-color: $bleu-liseret-client;
  border-bottom-width: 5px;
}

.bouton-participe:hover {
  color: #65ae43;
  border: #65ae43 solid 1px;
  font-weight: 600;
}

.bouton-participe {
  background-color: #65ae43;
  border: #65ae43 solid 1px !important;
  color: white;
}

.bouton-achat-credits {
  background-color: #1b90fb!important;
  border: #1b90fb solid 1px !important;
  color: white!important;
  cursor: initial;
}

.bouton-refus:hover {
  border: red solid 1px;
  color: red;
}

.bouton-refus {
  background-color: red;
  border: red solid 1px !important;
  color: white;
}

.texte-refus {
  color: red;
}

.bouton-afficher:hover {
  background-color: white!important;
  border: #1b90fb solid 1px!important;
  color: #1b90fb!important;
  font-weight: 500;
}

.bouton-afficher {
  background-color: #1b90fb!important;
  border: #1b90fb solid 1px !important;
  color: white!important;
  font-weight: 500;
}

.bouton-mode-emploi:hover {
  background-color: $bleu-idhome !important;
  color: #fff !important;
}

.bouton-participe,
.bouton-afficher,
.bouton-refus,
.bouton-achat-credits {
  border: solid 1.8px;
  border-radius: 54px;
  padding: 1px 20px;
  font-weight: 600;
}

.bouton-participe > .anticon,
.bouton-afficher > .anticon,
.bouton-refus > .anticon {
  border: solid 1.8px;
  border-radius: 54px;
  padding: 0.7px 1.75px;
}

.header-cartes-dashboard > .ant-card-head {
  background-color: $header-cartes-dashboard-bg;
  color: $header-cartes-dashboard-color;
}

.header-cartes-dashboard > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding-top:8px;
  padding-bottom:8px;  
}

.header-cartes-dashboard {
  flex: 1 1 auto;
  margin-bottom:1rem;
}

.colonnes-cartes-dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-cartes-info-dashboard > .ant-card-head {
  background-color: $header-cartes-dashboard-bg;
  color: $header-cartes-dashboard-color;
}

.header-cartes-info-dashboard > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding-top:8px;
  padding-bottom:8px;  
}

.header-cartes-info-dashboard {
  flex: 1 1 auto;
  margin-bottom:1rem;
}

.hauteur-fixe {
  height:220px;
  flex:0 1 auto;
}

.couleur-textes-dashboard {
  color: $gris-textes-dashboard!important;
}

.couleur-primaire {
  color: $bleu-idhome;
}

.titre-message-promotion {
  color: #65ae43!important;
  font-weight: 600!important;
  letter-spacing: 0.01em;
  
}

.texte-message-promotion {
  color:$gris-textes-dashboard!important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

.ant-table-thead {
  background-color: white;
}