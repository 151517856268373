.pricing-table-v1 {
  margin-bottom: 1.2rem;
  padding: 4em 2.4em 0.8em 2.4em;
  background-color: #fcfcfc;
  color: $body-color;
  border-radius: $border-radius-lg;
  box-shadow: none;
  height: 100%;
  display:flex;
  flex-direction: column;

  header {
    text-align:center;
    h2 {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1;
      color: $primary-color;
      //text-transform: uppercase;
      letter-spacing: 1px;
    }
    span {
      letter-spacing: normal;
      font-size: 1rem;
      font-weight: $font-weight-normal;
      color: $body-color;
    }
  }

  .pricing-price {
    text-align:center;
    margin-bottom: 0.2em;
    font-size: 35px;
    line-height: 1;
    color: $dark;
    font-weight: $font-weight-bold;
    span {
      margin-left: 0.4em;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1;
    }
  }

  .pricing-price-detail {
    text-align:center;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    color: $primary-color;
    line-height: 1.25rem;

    > span {
      text-decoration: line-through;
    }

    
  }

  .pricing-plan-details {
    flex:1;
    padding-top: 1rem;
    > ul {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      list-style: none;
      font-size: 0.9375rem;
      line-height: 1.5em;
      // color: #a1a8b9;
      color: $dark;
    }
  }

  > footer {
    // margin-top: 50px;
    > a {
      display: block;
      margin: 0;
      font-size: 16px;
    }

    .savings {
      margin-top:0.5rem;
      text-align: center;
      color:$primary-color;
    }
  }

  //
  &.pricing-table-highlight {
    //background-color: rgba($info, 0.08);
    border-top: solid $primary-color;
    border-width: 0.5rem;
  }
}
